import { Link } from "react-router-dom";

/* SAMPLE DATA */
import navMenu from "../../data/navigation";

/* ASSETS */
import Logo from "../../assets/images/logo.png";

const Sidebar = () => {
  var pathname = window.location.pathname;

  return (
    <section className="h-sidebar w-sidebar bg-[#141518] transition-all duration-300 ease-linear rounded-xl shadow-xl">
      {/* HEADER */}
      <div className="h-auto border-b border-white border-opacity-20 px-5 py-2 flex items-start text-start">
        <img
          src={Logo}
          className=" w-auto h-20 object-contain text-start "
          alt="LIBF"
        />
      </div>

      {/* NAVIGATION OPTIONS */}
      <div className="h-[90vh] flex flex-col justify-between">
        <div className="p-5 py-0">
          <div className=" mt-0">
            {/* <h5 className="text-xs font-proxima-semibold text-white text-opacity-50">
              MANAGE STORE
            </h5> */}
            {navMenu.map((nav) => (
              <Link
                to={nav.link}
                key={nav.id}
                className="w-full group flex items-center gap-3 first-of-type:mt-5 mt-7"
              >
                <nav.icon
                  className={`w-5 h-5 text-sidebarText ${pathname ===
                    nav.link &&
                    "text-white"} group-hover:text-white transition-all duration-300 ease-in-out`}
                />
                <div>
                  <p
                    className={`text-sm font-proxima-semibold text-sidebarText ${pathname ===
                      nav.link &&
                      "text-white"} group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                  >
                    {nav.name}
                  </p>
                </div>
              </Link>
            ))}
          </div>

          {/* MANAGE QUERIES */}
          {/* <div className="mt-10">
            <h5 className="text-xs font-proxima-semibold text-white text-opacity-50">
              MANAGE QUERIES
            </h5>
            {navMenu.slice(8, 9).map((nav) => (
              <Link
                to={nav.link}
                key={nav.id}
                className="w-full group flex items-center gap-3 first-of-type:mt-5 mt-7"
              >
                <nav.icon
                  className={`w-5 h-5 text-sidebarText ${pathname ===
                    nav.link &&
                    "text-white"} group-hover:text-white transition-all duration-300 ease-in-out`}
                />
                <div>
                  <p
                    className={`text-base font-proxima-semibold text-sidebarText ${pathname ===
                      nav.link &&
                      "text-white"} group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                  >
                    {nav.name}
                  </p>
                </div>
              </Link>
            ))}
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Sidebar;
